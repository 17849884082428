import {EscalaService} from '../../../modules/scf/services/escala.service';
import {SituacaoService} from './../services/situacao.service';
import {UnidadeService} from '../services/unidade.service';
import {TipoService} from './../services/tipo.service';
import {Component, OnInit} from '@angular/core';
import {ConfirmationService, MessageService} from 'primeng/api';
import {AppBreadcrumbService} from 'src/app/modules/main/breadcrumb/app.breadcrumb.service';
import {Unidade} from 'src/app/pages/escala/interfaces/unidade';
import {Situacao} from 'src/app/pages/escala/interfaces/situacao';
import {Escala} from '../interfaces/escala';
import {PageableEscala} from '../models/PageableEscala';
import {ActivatedRoute, Router} from '@angular/router';
import {TipoEscala} from '../interfaces/tipoEscala';
import {Jornada} from 'src/app/pages/escala/interfaces/jornada';
import {JornadaTrabalhoService} from './../services/jornada-trabalho.service';
import {IntervaloJornadaService} from '../services/intervalo-jornada.service';
import {IntervaloJornada} from '../interfaces/intervaloJornada';
import { OrgaosService } from 'src/app/modules/scf/services/orgaos.service';
import { Orgao } from 'src/app/modules/scf/models/orgao.model';
import { PaginateType } from 'src/app/modules/scf-core/types/paginate.type';
import { EscalaType } from 'src/app/modules/scf/models/types/escala.type';
import { TiposEscalasService } from 'src/app/modules/scf/services/tipos-escalas.service';
import { DropdownType } from 'src/app/modules/scf-core/types/dropdown.type';
import { EscalaFormFilterType } from 'src/app/modules/scf/models/types/escala-form-filter.type';
import { StatusIndicationEnum } from 'src/app/modules/scf-core/enums/status-indication.enum';
import { Messages } from 'src/app/modules/scf-core/constants/messages';


@Component({
    selector: 'app-escala-list',
    templateUrl: './escala-list.component.html',
    styleUrls: ['./escala-list.component.scss']
})


export class EscalaListComponent implements OnInit {
    isShowFormFilter = false;
    isToShowDialogConfirmDelete = false;
    autoCompleteOrgaos: object[]
    resultPaginated: PaginateType<EscalaType>;
    listTiposEscalas: TipoEscala[];
    formFilter: EscalaFormFilterType = {
        orgao: null,
        tipoEscala: null,
        descricao: '',

        // id: '',
        // idOrgao: '',
        // idOrganizacaoAdministrativa: 0,
        // situacao: '',
        // inicioVigencia: Date,
        // fimVigencia: '',
        // jornadaDiaria: '',
        // jornadaSemanal: '',
        // jornadaMensal: '',
        // horarioFlexivel: '',
        // bancoHoras: '',
        // escalaContinua: ''

    };

    listaOrgaos: Orgao[];
    listaOrgaosInferiores: Orgao[];
    orgaoInferiorSelecionado: Orgao;
    orgaoSelecionado: Orgao;
    listaUnidades: Unidade[];
    unidade: Unidade;
    totalElements: number;
    tituloDialog: '';

    isToShowJornada = false;


    tiposEscala: TipoEscala[];
    situacaoSelecionada?: Situacao;
    situacoes: Situacao[];
    escalas: Escala[];
    queryParam: string;
    escala: Escala;
    submitted = false;
    escalaDialog = false;
    listaJornadas: Jornada[];
    jornada: Jornada;
    listaIntervalosEscala: IntervaloJornada[];
    intervaloJornada: IntervaloJornada;
    listaEscalas: Escala[];
    escalaAux: any;


    constructor(
        private orgaosService: OrgaosService,
        private serviceUnidade: UnidadeService,
        private serviceSituacao: SituacaoService,
        private service: EscalaService,
        private serviceTiposEscalas: TiposEscalasService,
        private serviceJornadaTrabalho: JornadaTrabalhoService,
        private serviceIntervaloJornada: IntervaloJornadaService,
        private router: Router,
        private routeParam: ActivatedRoute,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private breadcrumbService: AppBreadcrumbService) {
        this.breadcrumbService.setItems([
            {label: 'Listar Escalas', routerLink: ['/escala-list']}
        ]);
    }

    ngOnInit(): void {
        this.getSituacoes();
        this.fetchSearch();
        this.fetchTiposEscalas();
    }

    onChangeOrgaoAutocomplete(event) {
        if (event.query.length >= 3) {
            this.fetchOrgaosByTagOrName(event.query);
        }
    }

    onChangePage(event: any) {
        const page = event.first / event.rows;
        this.fetchSearch(page);
    }

    handleSearch() {
        this.fetchSearch();
    }

    handleShowFilter() {
        this.isShowFormFilter = !this.isShowFormFilter;
    }

    handleClear() {
        for (const chave in this.formFilter) {
            delete this.formFilter[chave];
        }
        this.queryParam = "";
        // this.getpesquisaEscalas();
        this.fetchSearch();
    }

    fetchOrgaosByTagOrName(nome: string) {
        this.orgaosService.findAllByTagOrName({
            nome: nome,
            status: 'A'
        }).subscribe(response => {
            const results = response.content.map(item =>
                ({
                    name: item.organizacaoAdministrativa.nome,
                    code: item.id
                })
            )
            this.autoCompleteOrgaos = results;
        });
    }

    fetchTiposEscalas() {
        this.serviceTiposEscalas.findAll().subscribe((response) => {
            this.listTiposEscalas = response.data;
        });
    }

    fetchSearch(page = 0) {
        this.service
            .findAllPaginated(this.formFilter, page)
            .subscribe((response) => {
                this.resultPaginated = response;
            })
    }

    handleShowDialogJornada(escala) {
        this.getJornadasPorEscala(escala.id)
    }

    handleConfirmDelete(escala: EscalaType) {
        console.log('AQUI DEL')
        this.confirmationService.confirm({
            message: `Você realmente deseja excluir a escala: ${escala.descricao}`,
            header: 'Aviso!',
            icon: 'pi pi-info-circle',
            accept: () => {
                this.service.deleteById(escala.id).subscribe(() => {
                    this.fetchSearch();
                }, _ => {
                    this.messageService.add({severity:'error', summary:'Ops!', detail: Messages.error.service})
                });
            },
            reject: (type) => {
                console.log(type);
            }
        });
    }

    getEscalaPorId(idEscala: any) {
        this.service.listar(1).subscribe((response: PageableEscala) => {
            this.escalas = response.content;
            this.totalElements = response.totalElements;
        });
        this.escala = this.escalas[0];
    }

    getSituacoes() {
        this.serviceSituacao.listar().subscribe((situacoes) => {
            this.situacoes = situacoes;
        });
    }

    getUnidadesPorOrgao(idOrgao: any) {
        this.serviceUnidade.listar().subscribe((listaUnidades) => {
            this.listaUnidades = listaUnidades.filter(unidade => unidade.orgao.id === idOrgao);
        });
    }

    handleShowEscala(escala: Escala) {
        this.service.sharedData = escala;
        this.router.navigate([`/escala/${escala.id}`]);
    }

    handleEditEscala(escala: Escala) {
        this.service.sharedData = escala;
        this.router.navigate([`/escala/salvar/${escala.id}`]);
    }

    handleChangeStatus(item: EscalaType) {
        const data: any = item;
        data.status = item.status === StatusIndicationEnum.S ? StatusIndicationEnum.N : StatusIndicationEnum.S;
        data.orgao = {id: item.orgao.id}
        data.tipoEscala = {id: item.tipoEscala.id}
        console.log(data)
        this.service.save(data).subscribe(() => {
            this.fetchSearch();
        });
    }

    showEscalaPorTipo(escala: Escala) {
        if (escala.tipoEscala.nome === 'DIARIA') {
            this.router.navigate([`vinculo-escala-diaria/${escala.id}`]);
        }

        if (escala.tipoEscala.nome === 'REVEZAMENTO') {
            this.router.navigate([`vinculo-escala-revezamento/${escala.id}`]);
        }

        if (escala.tipoEscala.nome === 'TELEATENDIMENTO') {
            this.router.navigate([`vinculo-escala-teleatendimento/${escala.id}`]);
        }
    }

    activateStale(escala: Escala) {
        this.service.activateStale(escala);
    }

    inactivateStale(escala: Escala) {
        this.service.inactivateStale(escala);
    }

    getJornadasPorEscala(idEscala: any) {
        this.isToShowJornada = true;
        this.serviceJornadaTrabalho.listarJornadasPorEscala().subscribe((listaJornadas) => {
            this.listaJornadas = listaJornadas.filter(jornada => jornada.escala.id === idEscala);
        });

        this.serviceIntervaloJornada.listarIntervalosPorEscala().subscribe((listaIntervalosEscala) => {
            this.listaIntervalosEscala = listaIntervalosEscala.filter(intervalo => intervalo.escala.id === idEscala);
        });

        this.escalaAux = this.getEscalaPorId(idEscala);
        this.tituloDialog = this.escalaAux.iD;
    }

    getIntervalosPorEscala(idEscala: any) {
        this.isToShowJornada = true;
        this.serviceJornadaTrabalho.listarJornadasPorEscala().subscribe((listaJornadas) => {
            this.listaJornadas = listaJornadas.filter(jornada => jornada.escala.id === idEscala);
        });
    }

    obrirDialog() {
        this.escala = null;
        this.submitted = false;
        this.escalaDialog = true;
        console.log(this.listaOrgaos);
    }

    fechar(){
        this.router.navigate([`/`]);
    }

}
