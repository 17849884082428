<p-toast></p-toast>

<div class="card">

    <h4>Vincular Escala de Revezamento</h4>
    <div class="cabecalho">

    </div>
    <form class="formgrid grid">


        <div class="field col-6">
            <label for="nome">Nome:</label>
            <input pInputText [(ngModel)]="servidor.nome" placeholder="nome" name="nome" class="w-full" id="nome">
        </div>


        <div class="field col-6">
            <label for="nome">CPF:</label>
            <input pInputText [(ngModel)]="servidor.cpf" placeholder="cpf" name="cpf" class="w-full" id="nome">
        </div>


        <div class="field col-4">
            <label for="nome">Cód do Vínculo:</label>
            <input pInputText [(ngModel)]="servidor.codVinculo" placeholder="codVinculo" name="codVinculo"
                   class="w-full" id="codVinculo">
        </div>


        <div class="field col-4">
            <label for="orgaoInferior">Unidade Lotacao </label>
            <p-dropdown styleClass="w-full"
                        [options]="listaOrgaos"
                        placeholder="Selecione a unidade"
                        optionLabel="nome"
                        optionValue="id"
                        [(ngModel)]="orgaoSelecionado"
                        inputId="orgaoSelecionado"></p-dropdown>
        </div>

        <div class="field col-4">

            <p-checkbox [(ngModel)]="value" [binary]="true" label="Respeita Hierarquia?"></p-checkbox>
            <!--label for="orgaoSelecionado">Respeita Hierarquia </label-->
        </div>


        <div class="field col-6 r">
            <button pButton pRipple label="Pesquisar" icon="pi pi-search" class="p-button-outlined mr-3 "
                    (click)="pesquisa()"></button>
        </div>

    </form>

    <p-table #dt [value]="servidores" styleClass="p-datatable-gridlines" [(selection)]="servidoresSelecionados"
             dataKey="id" class="w-full" [rows]="10" [paginator]="true"
             [rowHover]="true" dataKey="id"
             currentPageReportTemplate="Mostrando {first} a {last} de {totalRecords} registros"
             [showCurrentPageReport]="true">
        <ng-template pTemplate="header">
            <tr>
                <th style="width: 4rem">
                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                </th>

                <th>Unidade Lotação</th>
                <th style="min-width:15rem">Nome</th>
                <th> CPF</th>
                <th>CodVinculo</th>
                <th> Cargo</th>
                <th> Inicio Revezamento</th>
                <!--<th> Calendário</th>-->

            </tr>

        </ng-template>
        <ng-template pTemplate="body" let-servidor>
            <tr>
                <td>
                    <p-tableCheckbox [value]="servidor"></p-tableCheckbox>
                </td>
                <td>{{servidor.unidade}}</td>
                <td>{{servidor.nome}}</td>
                <td>{{servidor.cpf}}</td>
                <td>{{servidor.codVinculo}}</td>
                <td>{{servidor.cargo}}</td>
                <td>{{servidor.inicioRevezamento}}</td>
                <!--<td><p-calendar [(ngModel)]="value" [showIcon]="true"></p-calendar></td>-->


            </tr>
        </ng-template>
    </p-table>

    <p-toolbar styleClass="mb-4 gap-2">
        <ng-template pTemplate="left">

        </ng-template>

        <ng-template pTemplate="right">
            <button pButton pRipple label=" Salvar " class="p-button-success mr-5" routerLink="/escalas"></button>
            <button pButton pRipple label="Cancelar" class="p-button-outlined" routerLink="/escalas"></button>


        </ng-template>
    </p-toolbar>


</div>

